import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { DropdownStateContext } from './DropdownProvider'
import ChevronIcon from '../../../../static/images/2023-ui/icons/ChevronDown.svg'
import './_dropdown.less'

const Dropdown = ({ selectedItem, children, dataCy }) => {
    const { isOpen, toggleDropdown, closeDropdown } = useContext(DropdownStateContext)
    const dropdownRef = useRef(null)

    useEffect(() => {
        const handleBodyClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                closeDropdown()
            }
        }

        document.addEventListener('mousedown', handleBodyClick)

        return () => {
            document.removeEventListener('mousedown', handleBodyClick)
        }
    }, [])

    return (
        <div ref={dropdownRef} className='dropdown' data-cy={`dropdown${dataCy ? `__${dataCy}` : ''}`}>
            <button className='dropdown__button' type='button' onClick={toggleDropdown}>
                {selectedItem ? selectedItem.label : 'Select'}
                <ChevronIcon className={`dropdown__button-icon ${isOpen ? 'dropdown__button-icon--open' : ''}`} />
            </button>
            {isOpen && (
                <ul className='dropdown__link-list' role='menu'>
                    {children}
                </ul>
            )}
        </div>
    )
}

Dropdown.propTypes = {
    selectedItem: PropTypes.shape({ label: PropTypes.string }),
    children: PropTypes.node,
    dataCy: PropTypes.string,
}

Dropdown.defaultProps = {
    selectedItem: { label: 'Select' },
    children: null,
    dataCy: '',
}

export default Dropdown
