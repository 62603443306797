import React from 'react';
import PropTypes from 'prop-types';
import LinkText from '../../../../components/atoms/LinkText/linkText';
import { Theme } from '../../../../constants/Constants';
import { ICONS } from '../../../../constants/Icons';
import './_footerModule.less';


const FooterModule = ({ headline, links, modifier }) => {
    if (!links) return null;

    const footerModuleClassName = `footer-module${modifier ? ` footer-module--${modifier}` : ''} `
    return (
        <div className={footerModuleClassName}>
            <h3
                className='footer-module__headline'
                data-cy='footer-module__headline'>
                {headline}
            </h3>
            <ul className='footer-module__links-list'>
                {links.map((link) => (
                    <li
                        className='footer-module__link'
                        key={`${link.title}${link.route}`}>
                        {renderLink(link)}
                    </li>
                ))}
            </ul>
        </div>
    );

    function renderLink(link) {
        if (!link) return null;

        return (
            <LinkText
                isExternal={link.isExternal}
                route={link.route}
                text={link.title}
                theme={Theme.DARK}
                icon={link.isHeadline ? ICONS.DocsIcon : undefined}
                linkModifier={`${link.isHeadline ? 'footer-module-headline' : 'footer-module'}`}
                dataCy='footer-module'
            />
        );
    }

};

FooterModule.propTypes = {
    headline: PropTypes.string.isRequired,
    links: PropTypes.array.isRequired,
    modifier: PropTypes.string,
};

FooterModule.defaultProps = {
    modifier: '',
};

export default FooterModule;
