import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardTitle, CardDescription } from '../../../../components/molecules/Card'
import LinkList from '../../../../components/molecules/LinkList/linkList'
import LinkText from '../../../../components/atoms/LinkText/linkText'
import { CardType } from '../../../../constants/Constants'
import './_developersArticles.less'

const DevelopersArticles = ({ headline, articles, sectionModifier }) => {
    if (!articles) return null

    return (
        <section className={`developers-articles ${sectionModifier ? ` developers-articles--${sectionModifier}` : ''}`}>
            <h2 className='developers-articles__headline' data-cy='developers-articles__headline'>
                {headline}
            </h2>
            <div className='developers-articles__cards'>
                {articles.map((article) => (
                    <div key={`${article.title}${article.description}`} className='developers-articles__card-container'>
                        <Card
                            type={CardType.GHOST}
                            modifier='card card--developers-articles'
                            dataCy={`developers-articles${article.dataCy ? `__${article.dataCy}` : ''}`}
                        >
                            <div className='developers-articles__card-content'>
                                <CardTitle title={article.title} />
                                <CardDescription text={article.description} />
                            </div>
                            <div className='developers-articles__link-container'>
                                {article.links ? (
                                    <LinkList
                                        links={article.links}
                                        modifier='link-list link-list--developers-articles'
                                        dataCy='developers-articles'
                                    />
                                ) : (
                                    <LinkText route={article.route} text='Learn more' dataCy='developers-articles' />
                                )}
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
        </section>
    )
}

DevelopersArticles.propTypes = {
    headline: PropTypes.string.isRequired,
    articles: PropTypes.array.isRequired,
    sectionModifier: PropTypes.string,
}

DevelopersArticles.defaultProps = {
    sectionModifier: '',
}

export default DevelopersArticles
