import { routePropTypes } from '../../utils/routes'
import React, { useState } from 'react'
import { DropdownProvider } from '../molecules/Dropdown/DropdownProvider'
import { Dropdown, DropdownItem } from '../molecules/Dropdown'
import LinkText from '../atoms/LinkText/linkText'

const VersionPicker = ({ metadata }) => {
    const hasVersions = metadata?.metadataParent?.hasVersionPicker || false
    const currentPath = metadata?.path

    const findCurrentVersionItem = (items, path) => {
        return items.find((child) => child.path === path && child.label)
    }

    const matchingCurrentVersionItem = hasVersions
        ? findCurrentVersionItem(metadata.metadataParent.metadataChildren, currentPath)
        : null

    const [currentVersionItem, setCurrentVersionItem] = useState(matchingCurrentVersionItem)

    function renderDropdown() {
        return (
            <DropdownProvider>
                <Dropdown selectedItem={currentVersionItem} onSelect={(version) => setCurrentVersionItem(version)}>
                    {metadata.metadataParent.metadataChildren.map((child) => (
                        <DropdownItem
                            key={`${child.title}${child.path}`}
                            onSelectItem={() => setCurrentVersionItem(child)}
                        >
                            <LinkText
                                text={child.title}
                                route={child.path}
                                isActive={child.path === metadata.path}
                                linkModifier='table-of-contents'
                            />
                        </DropdownItem>
                    ))}
                </Dropdown>
            </DropdownProvider>
        )
    }

    return <>{hasVersions && renderDropdown()}</>
}

VersionPicker.propTypes = {
    metadata: routePropTypes.pageMetadata.isRequired,
}

export default VersionPicker
