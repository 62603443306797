import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { routePropTypes } from '../../utils/routes'

import './_tableofcontents.less'
import NavigationNode from '../NavigationNode/NavigationNode'

/** Pages we want to ignore from the root level navigation */
const EXCLUSION_PATHS = ['/faq/', '/cla/', '/style-comp/']
const TOP_LEVEL_NAV_SPLIT_LENGTH = 3
const INTEGRATIONS_PATH = '/integrations/'

// TODO: turn this into a function that can get the first child of the respective key
const REDIRECT_MAP = new Map()
REDIRECT_MAP.set('/developers/', '/developers/credential-management')
REDIRECT_MAP.set('/guides/', '/guides/getting-started/create-an-input')

const TableOfContents = ({ metadata }) => {
    const data = useStaticQuery(graphql`
        query {
            allPageMetadata {
                edges {
                    node {
                        title
                        path
                        redirect
                        order
                    }
                }
            }
        }
    `)

    // Scroll to the active node on load
    useEffect(() => {
        const element = document.getElementById('header-section--active')
        if (element) {
            element.scrollIntoView({ behavior: 'instant' })
            // Ensure the document itself is not scrolled
            document.documentElement.scrollTo(0, 0)
        }
        // Manually apply margin to Safari to fix unsupported spacing css
        if (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')) {
            document.querySelector('.table-of-contents').classList.add('safari-margin')
        }
    }, [])

    const nodes = data.allPageMetadata.edges.map((edge) => edge.node)
    /** This filters pages to the top level of navigation.
     * We also have a set of paths that we do not want to render, so we ignore EXCLUSION_PATHS */
    const headerNodes = nodes.filter((node) => {
        const path = node.path
        return (
            // if you have a path that is at the root, when you split by '/' you will get ['', path, '']
            path.split('/').length === TOP_LEVEL_NAV_SPLIT_LENGTH && EXCLUSION_PATHS.indexOf(path) < 0
        )
    })

    const findChildren = (node, allNodes, parent) => {
        if (!node || !node.path || !allNodes || (node.metadataChildren && node.parent)) return

        if (!node.metadataChildren) {
            const children = allNodes.filter((child) => {
                if (!child.path.endsWith('/')) {
                    child.path += '/'
                }
                return (
                    child?.path &&
                    child.path !== node.path &&
                    child.path.startsWith(node.path) &&
                    child.path.split('/').filter((p) => p.length > 0).length ===
                        node.path.split('/').filter((p) => p.length > 0).length + 1
                )
            })
            node.metadataChildren = children.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
            node.parent = parent
        }

        node.metadataChildren.forEach((child) => {
            findChildren(child, allNodes, node)
        })
    }

    const getRoot = (node) => {
        let n = node
        while (n?.metadataParent) {
            n = n.metadataParent
        }
        return n
    }

    /** Hide Integrations on non-integrations pages */
    const hideIntegrations = (root) => {
        const integrationsIndex = root.metadataChildren?.findIndex((node) => node.path === INTEGRATIONS_PATH)
        if (integrationsIndex >= 0 && metadata?.path && !metadata.path.includes(INTEGRATIONS_PATH)) {
            root.metadataChildren.splice(integrationsIndex, 1)
        }
    }

    const buildRootNodes = (allNodes) => {
        const metadataRoot = getRoot(metadata)
        const root = allNodes.find((n) => n.path === metadataRoot.path)
        if (!root) return

        // Redirect root nodes so we don't get pulled away from the markdown view
        const nodes = [root, ...headerNodes.filter((node) => node.path !== root.path)]
            .sort((a, b) => {
                if (a.path < b.path) {
                    return -1
                }
                if (a.path > b.path) {
                    return 1
                }
                return 0
            })
            .map((node) => {
                const redirect = REDIRECT_MAP.get(node.path)
                return {
                    ...node,
                    redirect,
                }
            })
        return nodes
    }

    const rootNodes = buildRootNodes(nodes)
    const root = {
        title: 'root',
        path: '/',
        metadataChildren: rootNodes,
    }
    findChildren(root, nodes, undefined)
    hideIntegrations(root)

    return (
        <div className='table-of-contents'>
            <div className='dev-header'>
                {rootNodes.map((node) => {
                    return <NavigationNode key={node.path} node={node} depth={0} metadata={metadata} />
                })}
            </div>
        </div>
    )
}

TableOfContents.propTypes = {
    metadata: routePropTypes.pageMetadata.isRequired,
}

export default TableOfContents

// Supports up to 5 nested parents
export const query = graphql`
      fragment NavMetadata on PageMetadata {
        title
        path
        sourceCode
        metadataParent {
            title
            path
            sourceCode
            hasVersionPicker
            metadataChildren {
                title
                path
                order
                label
            }
            metadataParent {
                title
                path
                metadataChildren {
                    title
                    path
                    order
                    label
                }
                metadataParent {
                    title
                    path
                    metadataChildren {
                        title
                        path
                        order
                        label
                    }
                    metadataParent {
                        title
                        path
                        metadataChildren {
                            title
                            path
                            order
                            label
                        }
                        metadataParent {
                            title
                            path
                            metadataChildren {
                                title
                                path
                                order
                                label
                            }
                        }
                    }
                }
            }
        }
    }
`
