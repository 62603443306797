import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { ButtonTypes, Theme } from '../../../constants/Constants'
import './_linkButtonStandard.less'
import './_linkButtonPill.less'

// Gatsby's Link is used for internal navigation only. Pass isExternal=true to use <a> for external navigation
const LinkButton = (props) => {
    const { title, type, theme, isExternal, buttonModifier, dataCy } = props

    const linkButtonClassName = `link-button-${type} link-button-${type}--${theme} ${
        buttonModifier ? `link-button-${type}--${buttonModifier}` : ''
    }`

    const restProps = {}

    if (isExternal) {
        restProps.href = props.route
        restProps.onClick = props.onBtnClick
        restProps.rel = 'noopener noreferrer'
        restProps.target = '_blank'
    } else {
        restProps.to = props.route
    }

    const LinkComponent = isExternal ? 'a' : Link

    return (
        <LinkComponent
            className={linkButtonClassName}
            data-cy={`link-button${dataCy ? `__${dataCy}` : ''}`}
            {...restProps}
        >
            {title}
        </LinkComponent>
    )
}

LinkButton.propTypes = {
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    isExternal: PropTypes.bool,
    type: PropTypes.string,
    theme: PropTypes.string,
    buttonModifier: PropTypes.string,
    dataCy: PropTypes.string,
}

LinkButton.defaultProps = {
    isExternal: false,
    type: ButtonTypes.STANDARD,
    theme: Theme.LIGHT,
    buttonModifier: '',
    dataCy: '',
}

export default LinkButton
