const SHORTCUTS = [
    {
        title: 'JSON Reference',
        route: '/developers/apis/json-reference/',
    },
    {
        title: 'Events API',
        route: '/developers/apis/http/',
    },
    {
        title: 'Web SDK',
        route: '/developers/client-sdks/web/',
    },
    {
        title: 'iOS SDK',
        route: '/developers/client-sdks/ios/',
    },
    {
        title: 'Android SDK',
        route: '/developers/client-sdks/android/',
    },
]

const QUICKSTART_SDK_LINKS = [
    {
        title: 'Android',
        route: '/developers/quickstart/android/overview/',
        icon: 'AndroidIcon',
        iconPosition: 'left',
    },
    {
        title: 'iOS',
        route: '/developers/quickstart/ios/overview/',
        icon: 'IOSIcon',
        iconPosition: 'left',
    },
    {
        title: 'Web',
        route: '/developers/quickstart/web/overview/',
        icon: 'WebIcon',
        iconPosition: 'left',
    },
]

const ARTICLES = {
    SDK: [
        {
            title: 'Client SDK',
            description: 'Track and send customer data directly from your app to any server.',
            dataCy: 'client-sdk',
            links: [
                {
                    title: 'Android',
                    route: '/developers/client-sdks/android/initialization/',
                    icon: 'AndroidIcon',
                },
                {
                    title: 'iOS',
                    route: '/developers/client-sdks/ios/initialization/',
                    icon: 'IOSIcon',
                },
                {
                    title: 'Web',
                    route: '/developers/client-sdks/web/initialization/',
                    icon: 'WebIcon',
                },
                {
                    title: 'Alexa',
                    route: '/developers/client-sdks/alexa/',
                },
                {
                    title: 'AMP',
                    route: '/developers/client-sdks/amp/getting-started/',
                },
                {
                    title: 'Cordova',
                    route: '/developers/client-sdks/cordova/getting-started/',
                },
                {
                    title: 'Fire TV',
                    route: '/developers/client-sdks/android/initialization/',
                },
                {
                    title: 'Flutter',
                    route: '/developers/client-sdks/flutter/getting-started/',
                },
                {
                    title: 'React Native',
                    route: '/developers/client-sdks/react-native/getting-started/',
                },
                {
                    title: 'Roku',
                    route: '/developers/client-sdks/roku/getting-started/',
                },
                {
                    title: 'Unity',
                    route: '/developers/client-sdks/unity/getting-started/',
                },
                {
                    title: 'Xbox',
                    route: '/developers/client-sdks/uwp/getting-started/',
                },
            ],
        },
        {
            title: 'Server SDK',
            description: 'Track and send customer data between servers.',
            dataCy: 'server-sdk',
            links: [
                {
                    title: 'Go',
                    route: '/developers/server-sdks/go/',
                },
                {
                    title: 'Java',
                    route: '/developers/server-sdks/java/',
                },
                {
                    title: 'Node',
                    route: '/developers/server-sdks/node/',
                },
                {
                    title: 'Python',
                    route: '/developers/server-sdks/python/',
                },
                {
                    title: 'Ruby',
                    route: '/developers/server-sdks/ruby/',
                },
            ],
        },
        {
            title: 'Media SDK',
            description: 'Track media events and heartbeats across three platforms.',
            dataCy: 'media-sdk',
            links: [
                {
                    title: 'Android',
                    route: '/developers/client-sdks/android/media/',
                },
                {
                    title: 'iOS',
                    route: '/developers/client-sdks/ios/media/',
                },
                {
                    title: 'Web',
                    route: '/developers/client-sdks/web/media/',
                },
            ],
        },
    ],

    API: [
        {
            title: 'Events API',
            description: 'Collect data for contexts too complex for the Server SDK.',
            route: '/developers/apis/http/',
        },
        {
            title: 'Bulk Profile Deletion API',
            description: 'Save time when improving data quality by deleting data associated with sets of customers.',
            route: '/developers/apis/bulk-profile-deletion-api/',
        },
        {
            title: 'Calculated Attributes API',
            description: 'Send seeds for your calculated attributes.',
            route: '/developers/apis/ca-seeding-api/',
        },
        {
            title: 'Custom Access Roles API',
            description: 'Create and manage custom sets of permissions for your mParticle account users.',
            route: '/developers/apis/custom-roles/',
        },
        {
            title: 'Data Planning API',
            description: 'Define your data schema with Data Master.',
            route: '/developers/apis/dataplanning-api/',
        },
        {
            title: 'Data Subject Request API',
            description: 'Submit data subject requests for GDPR and CCPA compliance via the OpenDSR API.',
            route: '/developers/apis/dsr-api/v3/',
        },
        {
            title: 'ID Sync API',
            description: 'Match and resolve identities to build a single user profile for all user activities.',
            route: '/developers/apis/idsync/',
        },
        {
            title: 'Platform API',
            description: 'Programmatically update your mParticle inputs, outputs, filters, and more.',
            route: '/developers/apis/platform/',
        },
        {
            title: 'Pixel Service API',
            description: 'Send data to mParticle from your web site via a tracking pixel.',
            route: '/developers/apis/pixel-service/',
        },
        {
            title: 'Profile API',
            description: 'Access user profiles at scale to power personalized experiences.',
            route: '/developers/apis/profile-api/',
        },
        {
            title: 'Group Identity API',
            description: 'Create groups of users based on shared attributes.',
            route: '/developers/apis/group-identity-api/',
        },
        {
            title: 'Warehouse Sync API',
            description: 'Go beyond Reverse-ETL and build a headless data infrastructure.',
            route: '/developers/apis/warehouse-sync-api/overview/',
        },
    ],

    TOOLS: [
        {
            title: 'mParticle CLI',
            description: 'Access some mParticle features from a terminal.',
            route: '/developers/tools/cli/',
        },
        {
            title: 'Smartype',
            description: 'Manage your data plans as code.',
            route: '/developers/tools/smartype/',
        },
        {
            title: 'Smartype Linting',
            description: 'Lint your code against your data plan.',
            route: '/developers/tools/linting/',
        },
    ],

    MORE_GUIDES: [
        {
            title: 'Data Hosting',
            description: 'Understand how to reference the pod where your org is hosted.',
            route: '/developers/guides/data-localization/',
        },
        {
            title: 'ComposeID',
            description: 'Use IDSync to identify unknown users with data stored in Snowflake.',
            route: '/developers/apis/warehouse-sync-api/idsync/',
        },
        {
            title: 'Glossary',
            description: 'List of mParticle-specific terms.',
            route: '/guides/glossary/',
        },
        {
            title: 'Partners',
            description: 'Become an mParticle partner and make your platform available for input or output.',
            route: '/developers/guides/partners/introduction/',
        },
        {
            title: 'Rules',
            description: 'Cleanse and transform data before it is output to another system.',
            route: '/developers/guides/rules/',
        },
        {
            title: 'Segment Migration',
            description: 'Migrate your Segment implementation to mParticle.',
            route: '/developers/guides/migration-segment/overview/',
        },
    ],
}

export { SHORTCUTS, QUICKSTART_SDK_LINKS, ARTICLES }
