/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO, { getSeoTitleFromMetaData } from '../components/SEO';
import { routePropTypes } from '../utils/routes';
import { IntegrationLayout } from '../layouts/integration';
import HeadingsTOC from '../components/HeadingsTOC/headingstoc';

const IntegrationsMarkdownTemplate = (props) => {
    const { location, data } = props;
    const { currPageMetadata: metadata, markdownRemark: post } = data;
    const { frontmatter, html, parent } = post;
    const { modifiedTime } = parent ?? {};
    const { title, seoTitle, seoDescription } = frontmatter;
    const [scrollPosition, setScrollPosition] = useState(0);
    const markdownRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
          if (markdownRef.current) {
            // Get the scroll position of the child element
            setScrollPosition(markdownRef.current.scrollTop);
          }
        };
    
        // Add the scroll event listener to the child element
        const element = markdownRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }
    
        // Clean up the event listener when the component unmounts or markdownRef changes
        return () => {
          if (element) {
            element.removeEventListener('scroll', handleScroll);
          }
        };
      }, []);

    return (
        <IntegrationLayout currPageMetadata={metadata} location={location}>
            <div className="markdown__parent">
                <SEO
                    title={seoTitle || getSeoTitleFromMetaData(metadata)}
                    // Gastsby will return null if field does not exist
                    description={seoDescription || undefined}
                />
                <div className='markdown'>
                    <h1>{title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </div>
            <div className='markdown__rightNav'>
                <HeadingsTOC key='headings' currPath={metadata.path} modifiedTime={modifiedTime} scrollPosition={scrollPosition}/>
            </div>
        </IntegrationLayout>
    );
};

export const pageQuery = graphql`
    query IntegrationMarkdownMetadata($slug: String!) {
        currPageMetadata: pageMetadata(path: { eq: $slug }) {
            ...NavMetadata

            metadataParent {
                partnerId
            }
        }

        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                seoTitle
                seoDescription
            }
            parent {
                ... on File {
                    modifiedTime(formatString: "MMMM D, YYYY")
                }
            }
        }
    }
`;

IntegrationsMarkdownTemplate.propTypes = {
    location: routePropTypes.location.isRequired,
    data: PropTypes.shape({
        currPageMetadata: PropTypes.shape({
            metadataParent: PropTypes.object.isRequired,
        }).isRequired,
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }).isRequired,
            html: PropTypes.string.isRequired,
            headings: PropTypes.array,
        }).isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }).isRequired,
};

export default IntegrationsMarkdownTemplate;
