import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveContainer from '../ResponsiveContainer/responsiveContainer';
import MParticleLogo from '../MParticleLogo/mParticleLogo';
import FooterModule from './components/FooterModule/footerModule';
import FooterLegal from './components/FooterLegal/footerLegal';
import ScrollTopButton from './components/ScrollTopButton/scrolltopbutton';
import { FOOTER_MODULES, LEGAL_LINKS } from '../../constants/layouts/FOOTER';
import './_footer.less';

const settings = require('../../../settings.json');

const Footer = ({ footerModifier, hideContent }) => {
    const buildDate = settings && settings.buildDate && new Date(settings.buildDate);
    const currentYear = new Date().getFullYear();
    
    const handleShowOneTrustBanner = (e) => {
        e.preventDefault();
        window.OneTrust.ToggleInfoDisplay();
    };

    return (
        <footer
            id='docs-footer' // used in leftnavpane
            className={`footer${footerModifier ? ` footer--${footerModifier}` : ''}`}>
            {
                !hideContent && 
                <>
                    <ResponsiveContainer>
                        <nav className='footer__nav'>
                            <div className='footer__logo-wrapper'>
                                <MParticleLogo size='small' />

                                {buildDate ? (
                                    <small className='footer__nav-text footer__nav-text--build'>
                                        {`Last Updated:
                                    ${buildDate.toLocaleString()}`}
                                    </small>
                                ) : null}
                            </div>

                            <div className='footer__nav-container'>
                                {FOOTER_MODULES.map((module, index) => (
                                    <FooterModule
                                        key={module.headline}
                                        headline={module.headline}
                                        links={module.links}
                                        modifier={`${index}`}
                                    />
                                ))}
                            </div>
                        </nav>
                    </ResponsiveContainer>
                </>
            }
            <FooterLegal
                links={LEGAL_LINKS}
                currentYear={currentYear}
                onShowOneTrustBanner={handleShowOneTrustBanner}
            />
            <ScrollTopButton />
        </footer>
    );
}

Footer.propTypes = {
    footerModifier: PropTypes.string,
    hideContent: PropTypes.bool,
};

Footer.defaultProps = {
    footerModifier: '',
    hideContent: false,
}

export default Footer;
