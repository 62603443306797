import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Theme } from '../../../constants/Constants'
import './_linkText.less'

// Gatsby's Link is used for internal navigation only. Pass isExternal=true to use <a> for external navigation
const LinkText = (props) => {
    const { text, icon, iconPosition, isExternal, theme, isActive, linkModifier, dataCy } = props

    // setting different properties based on type of link
    const restProps = {}

    if (isExternal) {
        restProps.href = props.route
        restProps.onClick = props.onLinkClick
        restProps.rel = 'noopener noreferrer'
        restProps.target = '_blank'
    } else {
        restProps.to = props.route
    }

    const LinkComponent = isExternal ? 'a' : Link

    const linkTextClassName = `link-text link-text--${theme} ${isActive ? 'active' : ''} ${linkModifier ? `link-text--${linkModifier}` : ''}`

    return (
        <LinkComponent data-cy={`link-text${dataCy ? `__${dataCy}` : ''}`} className={linkTextClassName} {...restProps}>
            {!iconPosition || (iconPosition === 'left' && renderIcon())}
            {text}
            {iconPosition === 'right' && renderIcon()}
        </LinkComponent>
    )

    function renderIcon() {
        if (!icon) return null

        return <span className={`link-text__icon link-text__icon--${iconPosition}`}>{icon}</span>
    }
}

LinkText.propTypes = {
    text: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    icon: PropTypes.element,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    isExternal: PropTypes.bool,
    theme: PropTypes.string,
    linkModifier: PropTypes.string,
    isActive: PropTypes.bool,
    dataCy: PropTypes.string,
}

LinkText.defaultProps = {
    icon: null,
    iconPosition: 'left',
    isExternal: false,
    theme: Theme.LIGHT,
    linkModifier: '',
    isActive: false,
    dataCy: '',
}

export default LinkText
