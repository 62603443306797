/* eslint jsx-a11y/no-static-element-interactions: "off" */

import React from 'react'
import PropTypes from 'prop-types'

import './_leftnavpane.less'

class LeftNavPane extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggled: false,
        }
    }

    render() {
        return (
            <div ref={this.navRefCallback} className='left-nav-pane' data-hook='left-nav-pane'>
                <h4 className='left-nav-pane__title'>Documentation</h4>
                <span className='left-nav-pane__content'>{this.props.children}</span>
            </div>
        )
    }
}

export default LeftNavPane

LeftNavPane.propTypes = {
    children: PropTypes.node.isRequired,
}
