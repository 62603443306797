import { Constants } from '../Constants'

const FOOTER_MODULES = [
    {
        headline: 'CDP',
        links: [
            {
                title: 'mParticle Docs',
                route: Constants.PATHS.HOME,
                isHeadline: true,
            },
            {
                title: 'Home',
                route: Constants.PATHS.HOME,
            },
            {
                title: 'Guides',
                route: Constants.PATHS.GUIDES,
            },
            {
                title: 'Developers',
                route: Constants.PATHS.DEVELOPERS,
            },
            {
                title: 'Integrations',
                route: Constants.PATHS.INTEGRATIONS,
            },
        ],
    },
    {
        headline: 'Analytics',
        links: [
            {
                title: 'Analytics Docs',
                route: 'https://support.mparticle.com/hc/en-us/categories/11229686709645',
                isHeadline: true,
                isExternal: true,
            },
            {
                title: 'Overview',
                route: 'https://support.mparticle.com/hc/en-us/categories/11229701873421',
                isExternal: true,
            },
            {
                title: 'Getting Started',
                route: 'https://support.mparticle.com/hc/en-us/categories/11229686709645',
                isExternal: true,
            },
            {
                title: 'Developers',
                route: 'https://support.mparticle.com/hc/en-us/categories/11229725035533',
                isExternal: true,
            },
            {
                title: 'Expert Training',
                route: 'https://support.mparticle.com/hc/en-us/categories/11229720711693',
                isExternal: true,
            },
        ],
    },
    {
        headline: 'Predictive AI',
        links: [
            {
                title: 'Cortex Docs',
                route: 'https://www.vidora.com/docs/',
                isHeadline: true,
                isExternal: true,
            },
            {
                title: 'Overview',
                route: 'https://www.vidora.com/docs/?top-category=overview',
                isExternal: true,
            },
            {
                title: 'Getting Started',
                route: 'https://www.vidora.com/docs/?top-category=getting-started',
                isExternal: true,
            },
            {
                title: 'Developers',
                route: 'https://www.vidora.com/docs/?top-category=developer-docs',
                isExternal: true,
            },
            {
                title: 'Using Cortex',
                route: 'https://www.vidora.com/docs/?top-category=using-cortex',
                isExternal: true,
            },
        ],
    },
    {
        headline: 'Learn More',
        links: [
            {
                title: 'Blog',
                route: 'https://www.mparticle.com/blog/',
                isExternal: true,
            },
            {
                title: 'Podcast',
                route: 'https://www.mparticle.com/connected-podcast/',
                isExternal: true,
            },
            {
                title: 'Resources',
                route: 'https://www.mparticle.com/resources/',
                isExternal: true,
            },
            {
                title: 'Community',
                route: 'https://www.reddit.com/r/mparticle/',
                isExternal: true,
            },
            {
                title: 'Change log',
                route: 'https://changelog.mparticle.com/en',
                isExternal: true,
            },
        ],
    },
    {
        headline: 'Get in Touch',
        links: [
            {
                title: 'Help Center',
                route: 'https://support.mparticle.com/hc/en-us',
                isExternal: true,
            },

            {
                title: 'Demo',
                route: 'https://demo.mparticle.com/activity/app',
                isExternal: true,
            },
            {
                title: 'Become a Partner',
                route: 'https://www.mparticle.com/partner/',
                isExternal: true,
            },
        ],
    },
]

const LEGAL_LINKS = [
    {
        title: 'mParticle.com',
        route: 'https://www.mparticle.com/',
    },
    {
        title: 'Privacy Policy',
        route: 'https://www.mparticle.com/privacypolicy/',
    },
    {
        title: 'Cookie Policy',
        route: 'https://www.mparticle.com/cookie-policy/',
    },
]

export { FOOTER_MODULES, LEGAL_LINKS }
