import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveContainer from '../../../layouts/ResponsiveContainer/responsiveContainer'
import './_heroSection.less'

const HeroSection = ({ headline, children }) => (
    <section className='hero-section'>
        <ResponsiveContainer>
            <div className='hero-section__container'>
                <h1 className='hero-section__headline' data-cy='hero-section__headline'>
                    {headline}
                </h1>

                <div className='hero-section__shortcuts-container'>
                    <p className='hero-section__shortcuts-headline'>Shortcuts:</p>
                    <div className='hero-section__shortcuts-btn-wrapper'>{children}</div>
                </div>
            </div>
        </ResponsiveContainer>
    </section>
)

HeroSection.propTypes = {
    headline: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

export default HeroSection
