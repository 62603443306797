export function performSearch(term) {
    if (!term || term.length < 3) {
        return Promise.resolve({
            term: term,
            time: Date.now(),
            results: null,
        })
    }
    const time = Date.now()
    const AWS = require('aws-sdk/dist/aws-sdk-react-native')
    const csd = new AWS.CloudSearchDomain({
        region: 'us-east-1',
        endpoint: 'https://v3tm0bfimc.execute-api.us-east-1.amazonaws.com/prod',
    })

    const params = {
        query: term,
        highlight: '{"content":{"format": "html"}}',
    }

    return csd
        .search(params)
        .promise()
        .then((data) => {
            if (data?.hits && data.hits.found > 0) {
                return {
                    term: term,
                    time: time,
                    results: data.hits.hit,
                }
            }
            if (window.mParticle) {
                window.mParticle.logEvent('Search - Empty', window.mParticle.EventType.Other, {
                    search: term,
                })
            }
            return {
                term: term,
                time: time,
                results: null,
            }
        })
}
