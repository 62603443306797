import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DropdownStateContext } from './DropdownProvider'

import './_dropdown.less'

const DropdownItem = ({ children, onSelectItem }) => {
    const { closeDropdown } = useContext(DropdownStateContext)

    const handleClick = () => {
        onSelectItem()
        closeDropdown()
    }

    return (
        <li className='dropdown__link' onClick={handleClick}>
            {children}
        </li>
    )
}

DropdownItem.propTypes = {
    children: PropTypes.node,
    onSelectItem: PropTypes.func,
}

DropdownItem.defaultProps = {
    children: null,
    // biome-ignore lint/suspicious/noEmptyBlockStatements:
    onSelectItem: () => {},
}

export default DropdownItem
