import React, { createContext, useState } from 'react'

export const DropdownStateContext = createContext({})

export function DropdownProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false)

    const toggleDropdown = () => setIsOpen(!isOpen)

    const closeDropdown = () => setIsOpen(false)

    return (
        <DropdownStateContext.Provider value={{ isOpen, toggleDropdown, closeDropdown }}>
            {children}
        </DropdownStateContext.Provider>
    )
}
