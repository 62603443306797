import React from 'react'
import PropTypes from 'prop-types'
import CardTypeContext from './CardTypeContext'
import { CardType } from '../../../constants/Constants'
import './_card.less'

const Card = ({ type, modifier, children, dataCy }) => {
    const cardClassName = `card card--${type} ${modifier ? ` card--${modifier}` : ''}`

    return (
        <CardTypeContext.Provider value={type}>
            <div data-cy={`card__${dataCy}`} className={cardClassName}>
                {children}
            </div>
        </CardTypeContext.Provider>
    )
}

Card.propTypes = {
    type: PropTypes.string,
    modifier: PropTypes.string,
    children: PropTypes.node,
    dataCy: PropTypes.string,
}

Card.defaultProps = {
    type: CardType.EMPHASIZE,
    modifier: '',
    children: null,
    dataCy: '',
}

export default Card
