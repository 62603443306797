import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import TypeContext from './CardTypeContext'
import './_card.less'

const CardDescription = ({ text }) => {
    const type = useContext(TypeContext)
    const cardDescriptionClassName = `card__description card__description--${type}`

    return <p className={cardDescriptionClassName}>{text}</p>
}

CardDescription.propTypes = {
    text: PropTypes.string.isRequired,
}

export default CardDescription
