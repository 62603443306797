import { addGlobalEventListener } from './src/utils/misc'
import './src/styles/custom-code-buttons.less'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/command-line/prism-command-line.css'

// This code fixes the issue of React not being able to navigate directly
// to 'hash' locations as part of page load or navigation.
function scrollToHash(location) {
    if (location.hash) {
        // For some reason, after navigating a while the window history ends up keeping
        // a stale state with the same key - this tells the router that subsequent hashChange
        // events are not new navigations and thus the listeners are not notified.
        if (window.history?.state) {
            window.history.state.key = null
        }
        setTimeout(() => {
            try {
                const content = document.querySelector(`${location.hash}`)
                if (content?.scrollIntoView) {
                    content.scrollIntoView()
                } else if (window.mParticle) {
                    window.mParticle.logPageView('Error 404', {
                        targetUrl: `${location.pathname}${location.hash}`,
                    })
                }
            } catch (e) {
                logError(e)
            }
        }, 0)
    } else {
        // If there isn't a hash, scroll to the top just to be safe
        window.scrollTo(0, 0)
    }
}
export const onRouteUpdate = ({ location }) => {
    scrollToHash(location)
    if (window.mParticle) {
        window.mParticle.logPageView(
            'Docs Page View',
            {
                anchor: location.pathname,
            },
            {
                'Google.Page': location.pathname,
            },
        )
    }
}

function logError(e) {
    if (window.mParticle) {
        window.mParticle.logEvent('Docs error', window.mParticle.EventType.Other, { error: e })
    }
}

addGlobalEventListener('onerror', logError)
window.addEventListener('error', logError)

// addGlobalEventListener('onhashchange', () => scrollToHash(window.location));

// Polyfill for 'matches' function -- used for checking on outside clicks for search, flyouts
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
            const matches = (this.document || this.ownerDocument).querySelectorAll(s)
            let i = matches.length
            // biome-ignore lint/suspicious/noEmptyBlockStatements:
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1
        }
}
