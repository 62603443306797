import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { routePropTypes } from '../../utils/routes'
import HeaderFooterLayout from '../../layouts/headerfooter'
import SEO from '../../components/SEO'
import HeroSection from '../../components/sections/HeroSection/heroSection'
import PageSection from '../../components/sections/PageSection/pageSection'
import LinkButton from '../../components/atoms/LinkButton/linkButton'
import OnboardingCard from './components/OnboardingCard/onboardingCard'
import DevelopersArticles from './components/DevelopersArticles/developersArticles'

import { SHORTCUTS, QUICKSTART_SDK_LINKS, ARTICLES } from '../../constants/pages/DEVELOPERS'
import { ButtonTypes, Theme } from '../../constants/Constants'

import './_developers.less'

const DevContent = (props) => {
    const LEARN_ABOUT_MP_LINK = [
        {
            title: 'Read the docs',
            route: '/developers/developersjourney/',
            icon: 'ChevronRight',
            iconPosition: 'right',
        },
    ]

    return (
        <HeaderFooterLayout location={props.location} metadata={props.data.pageMetadata} contentFixedWidth={false}>
            <SEO
                title='mParticle developer documentation'
                description='Learn more about implementing mParticle with detailed documentation on our client SDKs and HTTP APIs.'
            />
            <HeroSection headline='Developer Docs'>
                {SHORTCUTS.map((shortcut) => (
                    <LinkButton
                        key={`${shortcut.title}${shortcut.route}`}
                        title={shortcut.title}
                        route={shortcut.route}
                        dataCy='developers-shortcuts'
                        type={ButtonTypes.PILL}
                        theme={Theme.DARK}
                    />
                ))}
            </HeroSection>

            <PageSection sectionModifier='page-section page-section--developers-onboarding'>
                <p className='developers-onboarding__text'>
                    Use the mParticle SDKs and APIs to collect, organize, and manage customer data.
                </p>

                <div className='developers-onboarding__cards'>
                    <OnboardingCard
                        title='Quickstart'
                        description='Pick a platform, and follow the SDK quickstart tutorial
                                            to see how easy it is to collect data and forward
                                            it to a third-party integration.'
                        links={QUICKSTART_SDK_LINKS}
                    />

                    <OnboardingCard
                        title='Learn about mParticle'
                        description='From event tracking to predicting user behavior,
                                            the developer docs guide you through each stage of building
                                            a complete mParticle implementation.'
                        links={LEARN_ABOUT_MP_LINK}
                    />
                </div>
            </PageSection>

            <PageSection sectionModifier='page-section page-section--developers-articles'>
                {renderArticlesSection('SDK', ARTICLES.SDK)}
                {renderArticlesSection('API', ARTICLES.API)}
                {renderArticlesSection('Tools', ARTICLES.TOOLS)}
                {renderArticlesSection('More Guides', ARTICLES.MORE_GUIDES)}
            </PageSection>
        </HeaderFooterLayout>
    )

    function renderArticlesSection(headline, articles) {
        return (
            <DevelopersArticles
                headline={headline}
                articles={articles}
                sectionModifier='developers-articles developers-articles--bottom-margin'
            />
        )
    }
}

export const frontmatter = {
    title: 'Developers',
    showWhenLast: true,
    tocItem: true,
}

export const query = graphql`
    query {
        pageMetadata (path: {eq: "/developers/"}) {
            ...BreadcrumbMetadata
        }
    }
`

DevContent.propTypes = {
    location: routePropTypes.location.isRequired,
    data: PropTypes.shape({
        pageMetadata: routePropTypes.pageMetadata,
    }).isRequired,
}

export default DevContent
