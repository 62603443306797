import React from 'react'
import { Link } from 'gatsby'
import { routePropTypes } from '../../utils/routes'
import PropTypes from 'prop-types'
import './_navigationNode.less'

/** Flatten children if they are versioned into a single child */
function processChildrenVersions(children, metadata) {
    if (!children || children.length === 0) return children
    // Verify if all children are versioned by checking `label` attribute
    let versioned = true
    let i = 0
    let n = children[i]
    // Check for strings like 'v1', 'V2', etc.
    const regex = /\bv[0-9]+\b/i
    while (n && versioned) {
        versioned = regex.test(n.label)
        n = children[++i]
    }
    if (versioned) {
        // Show just a single "Overview" page
        const child = {
            title: 'Overview',
            path: metadata.path,
        }
        // biome-ignore lint/style/noParameterAssign:
        children = [child]
    }
    return children
}

function getChildrenForPath(path, node) {
    let n = node
    while (n) {
        if (n.path === path) {
            return n.metadataChildren ?? []
        }
        n = n.metadataParent
    }
    return []
}

const NavigationNode = ({ node, depth, metadata }) => {
    // Ignore any node that won't display correctly
    if (!node || !node.title || !node.path || node.title.trim() === '') return <></>

    let children = node.metadataChildren ?? getChildrenForPath(node.path, metadata)
    children = processChildrenVersions(children, metadata)
    const hasChildren = children?.length > 0

    const isActiveParent = metadata.path.includes(node.path) && depth === 1

    if (depth === 0) {
        return (
            <div className='header-section__container'>
                <h2 className='header-section header-section__root'>{node.title}</h2>
                <div className='header-section__children'>
                    {children.map((child) => (
                        <NavigationNode key={child.path} node={child} depth={depth + 1} metadata={metadata} />
                    ))}
                </div>
            </div>
        )
    }

    // Recursively render each page node and its sub-routes
    return hasChildren ? (
        <details
            className={`header-section ${depth === 0 ? 'header-section__root' : ''}`}
            open={metadata.path.includes(node.path)}
        >
            <summary
                className={`header-section__title ${hasChildren ? 'header-section__parent' : 'header-section__child'} ${depth > 0 ? `header-section__child--${depth}` : ''} ${isActiveParent ? 'header-section__parent--selected' : ''}`}
            >
                <span>{node.title}</span>
            </summary>
            <span className='header-section__children'>
                {children.map((child) => (
                    <NavigationNode key={child.path} node={child} depth={depth + 1} metadata={metadata} />
                ))}
            </span>
        </details>
    ) : (
        <Link
            className={`header-section__link ${depth === 0 ? 'header-section__root' : ''}`}
            to={node.redirect || node.path}
        >
            <p
                id={node.path === metadata.path ? 'header-section--active' : undefined}
                className={`header-section ${depth > 0 ? `header-section__child header-section__child--${depth}` : ''} ${node.path === metadata.path ? 'header-section--selected' : ''}`}
            >
                {node.title}
            </p>
        </Link>
    )
}

NavigationNode.propTypes = {
    metadata: routePropTypes.pageMetadata.isRequired,
    node: PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
        redirect: PropTypes.string,
    }),
    depth: PropTypes.number,
}

export default NavigationNode
