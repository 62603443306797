const Constants = {
    PATHS: {
        HOME: '/',
        DEVELOPERS: '/developers/',
        GUIDES: '/guides/',
        INTEGRATIONS: '/integrations/',
        CHANGELOG: 'https://changelog.mparticle.com',
    },
}

const Theme = {
    LIGHT: 'light',
    DARK: 'dark',
}

const ButtonTypes = {
    STANDARD: 'standard',
    PILL: 'pill',
}

const CardType = {
    EMPHASIZE: 'emphasize',
    GHOST: 'ghost',
}

const MAX_LINKS_PER_COLUMN = 6

export { Constants, Theme, ButtonTypes, CardType, MAX_LINKS_PER_COLUMN }
